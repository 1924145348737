<template>
  <div class="container" :style="{bottom: bottom, left: left}">
    <div style="display: flex; justify-content: space-between; width: 22.92vw">
      <div class="bigTitle">党员</div>
      <div class="closeButton" @click="closeWindow">
        <img src="@/assets/common/close.png" class="closeBtnImg" alt="">
      </div>
    </div>
    <div class="buttonRow">
		<short-button class="shortButton" itemName="全部" :value="dataInput[0].length" :selected="selectedIndex==0" @click="()=>{selectType(0)}"></short-button>
		<short-button class="shortButton" itemName="机关党员" :value="dataInput[1].length" :selected="selectedIndex==1" @click="()=>{selectType(1)}"></short-button>
		<short-button class="shortButton" itemName="两新党员" :value="dataInput[2].length" :selected="selectedIndex==2" @click="()=>{selectType(2)}"></short-button>
		<short-button class="shortButton" itemName="困弱党员" :value="dataInput[3].length" :selected="selectedIndex==3" @click="()=>{selectType(3)}"></short-button>
	</div>
	<div class="buttonRow">
		<short-button class="shortButton" itemName="流动党员" :value="dataInput[4].length" :selected="selectedIndex==4" @click="()=>{selectType(4)}"></short-button>
		<short-button class="shortButton" itemName="退役军党" :value="dataInput[5].length" :selected="selectedIndex==5" @click="()=>{selectType(5)}"></short-button>
		<short-button class="shortButton" itemName="银龄党员" :value="dataInput[6].length" :selected="selectedIndex==6" @click="()=>{selectType(6)}"></short-button>
		<div class="shortButton"><!-- 空元素占位 --></div>
	</div>
	<table-3 class="table" :columns="tableColumns" :data="dataInput[selectedIndex]"></table-3>
  </div>
</template>
<script>
const shortButton = () => import('@/views/components/shortButton.vue');
const table3 = () => import('@/views/components/table3.vue');
//const scrollview = () => import('@/views/components/scrollview.vue');
export default {
  name: 'partyDetail',
  props: ['bottom', 'left', 'dataInput'],
  data() {
    return {
		selectedIndex: 0,
		tableColumns: [
			{
				title: '姓名',
				key: 'name',
				width: 17.27
			},{
				title: '性别',
				key: 'sex',
				width: 9.77
			},{
				title: '类别',
				key: 'type',
				width: 16.36
			},{
				title: '住址',
				key: 'address',
				width: 29.09
			},{
				title: '联系方式',
				key: 'contactInformation',
				width: 24.78
			}
		]
    }
  },
  components:{
    shortButton,
	table3
    //scrollview
  },
  mounted(){
	this.initData();
    return false;
  },
  methods: {
    initData(){
		this.selectedIndex = 0;
    },
    selectType(n){
      this.selectedIndex = n;
    },
    closeWindow(){
      this.$emit('closeWindow');
    }
  },
  watch: {
  
  },

}
</script>
<style lang="less" scoped>
.container {

  z-index: 9999;
  width: 26.05vw;
  height: 82.5vh;
  border: 1px solid;
  background: linear-gradient(90deg, rgba(72, 64, 42, 0.4) 0%, rgba(61, 61, 61, 0) 100%);
  border-image: linear-gradient(270deg, rgba(255, 208, 59, 1), rgba(255, 208, 59, 0.2)) 1 1;
  box-shadow: 0px 0px 20px 0px rgba(255, 208, 59, 0.2);
  padding-left: 1.5625vw;
  padding-top: 2.78vh;
  text-wrap: nowrap;
  color: #fff;
  overflow: hidden;

  .bigTitle {
    font-family: 'titleFont';
    font-size: 2.22vh;
    font-weight: 400;
    background: linear-gradient(180deg, #FFFFFF 42%, #FFD03B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .closeButton {
    height: 2.22vh;
    aspect-ratio: 1/1;
    cursor: pointer;

    .closeBtnImg {
      width: 100%;
      height: 100%;
    }
  }
  .closeButton:hover {
	background: rgba(255, 208, 59, 0.2);
  }
  .buttonRow {
	width: 22.92vw;
	display: flex;
	height: 2.78vh;
	margin-top: 0.74vh;
	justify-content: left;
	
	.shortButton {
		width: 5.41vw;
		height: 2.78vh;
		margin-right: 0.41vw;
		flex: 1;
	}
  }
  .table {
	width: 22.92vw;
	margin-top: 1.11vh;
	height: 70vh;
  }
}
</style>