<template>
    <div id="app">
		<Map v-if="loadMap"></Map> 
		<router-view/>
    </div>
</template>

<script>
import Map from './Map.vue';
export default {
    name: 'App',
    components:{Map},
    data() {
        return {
            resizeFn: null,
            loadMap: false,
            loginRefreshTimer: null,
        }
    },
    mounted() {
        const documentWidth = document.body.offsetWidth;
        const ratio = documentWidth / 1920;
        if (documentWidth > 1920) {
            document.body.style.transform = `scale(${ratio}, ${ratio})`
        }
        this.resizeFn = this.$debounce(function () {
            const documentWidth = document.body.offsetWidth;
            const ratio = documentWidth / 1920;
            if (documentWidth > 1920) {
                document.body.style.transform = `scale(${ratio}, ${ratio})`
            }
        }, 200)
        window.addEventListener('resize',this.resizeFn);
        /* let token = this.$db.get('token');
        if(token){
            this.loadMap = true;
        } */
        // 检查当前打开页面时与最近一次登录的时间间隔，若超过30分钟则跳转到登录页面并清空本地存储
        // 否则正常开启页面并更新一次登录状态
        let ts = new Date();
        ts = ts.getTime();
        let loginStatus = this.$db.get('token') && this.$db.get('pwd') && this.$db.get('loginTime');
        let oldTs = this.$db.get('loginTime');

        if(ts - oldTs >= 1750000 || !loginStatus){
            this.$db.clear();
            this.$router.push('login');
        }else{
            this.checkLoginStatus();
            this.setloginTimer();
            this.loadMap = true;
        }
        this.$eventBus.$on('onLogin', () => {
            this.setloginTimer();
            this.loadMap = true;
        })
    },
    methods: {
        setloginTimer(){
            clearInterval(this.loginRefreshTimer);
            this.loginRefreshTimer = setInterval(() => {
                this.checkLoginStatus();
            }, 20*60*1000)
        },
        checkLoginStatus(){
            let pwd = this.$db.get('pwd');
            this.$api.login({
                username: 'admin',
                password: pwd
            }).then(res => {
                let code = res.data.code;
                if(code != 200){
                    //网络连接出现问题，退回到登录界面
                    this.$db.clear();
                    this.$router.push('login');
                    clearInterval(this.loginRefreshTimer);
                }else{
                    // 登录成功，更新令牌
                    let ts = new Date();
                    ts = ts.getTime();
                    this.$db.set({
                        token: res.data.token,
                        loginTime: ts
                    });
                }
            }).catch(err => {
                console.log(err);
                this.$db.clear();
                this.$router.push('login');
                clearInterval(this.loginRefreshTimer);
            })
        }
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resizeFn);
        clearInterval(this.loginRefreshTimer);
    }
}
</script>

<style lang="less">
@import "~@/assets/less/index.less";

html,body {
    height: 100%;
    width: 100%;
    padding:0;
    margin:0;
}
html {
    font-size: 20px;
}
body {
    transform-origin: left top;
    background-size: 100% 100%;
}
#app {
    height: 100%;
    font-family: 'pingfangSc', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
	background-color: @bgColor;
	user-select: none;
	touch-action: none;
}
</style>
