<template>
    <div class="loginPage">
        <div class="blackTitle">昭美社区大屏管理</div>
		<img src="@/assets/common/Maskgroup.png" class="bgp" alt="">
		<div class="formBox">
			<div class="formTitle">欢迎使用</div>
			<div class="formSubtitle" :style="{color: statusColor}">{{status}}</div>
			<input type="password" class="inputer" placeholder="密码" ref="input" v-model="password" :style="{borderColor}"/>
			<div class="loginButton" @click="handleLogin">登录</div>
		</div>
		<div class="keyboard" @mousedown.prevent>
			<keyboard @onKeyboard="handleKeyboard"></keyboard>
		</div>
		
    </div>
</template>

<script>
const keyboard = () => import('./components/keyboard.vue');

export default {
    name: 'login',
	components:{
		keyboard
	},
    data() {
        return {
            password: '',
			status: '请输入密码',
			statusColor: '#969ba0',
			borderColor: '#e2e2ea'
        }
    },
    mounted() {

    },
	watch:{
	
	},
    beforeDestroy(){
        return false;
    },
    methods: {
		handleKeyboard(ev){
			this.borderColor = '#e2e2ea';
			let p = this.password;
			if(ev == 'space'){
				p += ' ';
			}else if(ev == 'back'){
				p = p.substring(0, p.length-1);
			}else if(ev == 'redo'){
				p = '';
			}else if(ev == 'enter'){
				this.handleLogin();
			}else {
				p += ev;
			}
			this.password = p;
		},
		handleLogin(){
			//先设置假密码。后面需要发送到后端进行验证。
			/* if(this.password == 'E4ac#z'){
				//密码正确，跳转到首页
				this.status = '登陆成功！';
				this.statusColor = '#0d0';
				this.$db.set({
					login: 'success'
				});
				this.$router.push('page1');
			}else{
				//密码错误，提示重输
				this.status = '密码错误！';
				this.statusColor = '#ff3b3b';
				this.borderColor = '#ff3b3b';
			} */
			let pwd = this.password;
			this.$api.login({
				username: 'admin',
				password: pwd
			}).then(res => {
				let code = res.data.code;
				if(code != 200){
					if(res.data.msg == '用户不存在/密码错误'){
						//密码错误，提示重输
						this.status = '密码错误！';
						
					}else{
						this.status = '网络连接错误';
					}
					this.statusColor = '#ff3b3b';
					this.borderColor = '#ff3b3b';
				}else{
					//密码正确，跳转到首页
					this.status = '登陆成功！';
					this.statusColor = '#0d0';
					let ts = new Date();
					ts = ts.getTime();
					this.$db.set({
						token: res.data.token,
						pwd,
						loginTime: ts
					});
					this.$eventBus.$emit('onLogin');
					this.$router.push('page1');
				}
			}).catch(err => {
				console.log(err);
			})
		}
    },
}
</script>

<style lang="less" scoped>

@import "~@/assets/less/index.less";

.loginPage {
	position: relative;
	width: 100vw;
	height: 100vh;
	background: #fff9ee;
	opacity: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 20000;
	
	.blackTitle {
		position: absolute;
		font-family: 'titleFont', sans-serif;
		font-size: 2.22vh;
		top: 3.7vh;
		left: 2.08vw;
	}
	
	.formBox {
		width: 16.67vw;
		height: 26.3vh;
		background: #fff;
		border-radius: 1.48vh;
		opacity: 1;
		
		.formTitle {
			font-size: 1.85vh;
			font-family: 'pingfangSc', sans-serif;
			color: #000;
			margin-top: 2.22vh;
			text-align: center;
		}
		
		.formSubtitle {
			font-size: 1.48vh;
			font-family: 'pingfangSc', sans-serif;
			margin-top: 3.7vh;
			text-align: center;
		}
		
		.inputer {
			height: 3.7vh;
			width: 13.54vw;
			border: #e2e2ea 1px solid;
			border-radius: 0.37vh;
			color: #333;
			margin-top: 0.74vh;
			margin-left: 1.5625vw;
			font-size: 1.48vh;
			padding-left: 3%;
		}
		.inputer:focus {
			outline: none;
		}
		.loginButton {
			height: 3.7vh;
			width: 13.54vw;
			margin-left: 1.5625vw;
			margin-top: 3.7vh;
			border-radius: 0.37vh;
			background: #f3b805;
			color: #fff;
			font-size: 1.48vh;
			line-height: 3.7vh;
			text-align: center;
			font-family: 'pingfangSc', sans-serif;
			cursor: pointer;
		}
	}
	
	.bgp {
		width: 100%;
		position: absolute;
		bottom: 0;
		z-index: -1
	}
	.keyboard {
		position: absolute;
		bottom: 0;
		margin: auto
	}
}
</style>
