<template>
  <div class="container" :style="{bottom: bottom, left: left}">
    <div style="display: flex; justify-content: space-between; width: 51.04vw">
      <div class="bigTitle">{{videoName}}</div>
      <div class="closeButton" @click="closeWindow">
        <img src="@/assets/common/close.png" class="closeBtnImg" alt="">
      </div>
    </div>
    <div class="videoBox">
      <hls-camera :videoSrc="videoUrl"></hls-camera>
    </div>
  </div>
</template>
<script>
const hlsCamera = () => import('@/views/components/hlsCamera.vue');

export default {
  name: 'bigVideo',
  props: ['videoUrl', 'videoName'],
  data() {
    return {

    }
  },
  components:{
    hlsCamera

  },
  mounted(){
	this.initData();
    return false;
  },
  methods: {
    initData(){
      
    },
    closeWindow(){
      this.$emit('closeWindow');
    }
  },
  watch: {
  
  },

}
</script>
<style lang="less" scoped>
.container {

  z-index: 9999;
  width: 54.17vw;
  height: 57.04vh;
  border: 1px solid;
  background: linear-gradient(90deg, rgba(72, 64, 42, 0.4) 0%, rgba(61, 61, 61, 0) 100%);
  border-image: linear-gradient(270deg, rgba(255, 208, 59, 1), rgba(255, 208, 59, 0.2)) 1 1;
  box-shadow: 0px 0px 20px 0px rgba(255, 208, 59, 0.2);
  padding-left: 1.5625vw;
  padding-top: 2.78vh;
  text-wrap: nowrap;
  color: #fff;
  overflow: hidden;

  .bigTitle {
    font-family: 'titleFont';
    font-size: 2.22vh;
    font-weight: 400;
    background: linear-gradient(180deg, #FFFFFF 42%, #FFD03B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .closeButton {
    height: 2.22vh;
    aspect-ratio: 1/1;
    cursor: pointer;

    .closeBtnImg {
      width: 100%;
      height: 100%;
    }
  }
  .closeButton:hover {
	background: rgba(255, 208, 59, 0.2);
  }
  
  .videoBox {
    width: 51.04vw;
    height: 46.3vh;
    margin-top: 2.96vh;



  }
}
</style>