import Vue from 'vue';
import App from './App';
import router from './router';
import iView from 'iview';
import './assets/less/index.less';
import echarts from 'echarts';
import utils from "./lib/utils";
import api from './ajax/requests';
import { $db, $c, $u } from './utils';
import VideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-hls';


Vue.prototype.$echarts = function (el) {
    //return echarts.init(el, null, {renderer: 'svg'}); //使用svg进行渲染
    return echarts.init(el); //使用canvas进行渲染。由于使用svg渲染折线图时有显示不全问题，所以使用canvas，图形精度有影响
}
Vue.prototype.$api = api; //挂载网络接口操作对象
Vue.prototype.$db = $db; //挂载localStorage操作对象
Vue.prototype.$c = $c; //挂载项目配置对象
Vue.prototype.$u = $u; //挂载工具函数管理对象
Vue.prototype.$eventBus = new Vue();
Vue.config.productionTip = false;
Vue.use(iView);
Vue.use(utils);
Vue.use(VideoPlayer);
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
