<template>
  <div class="map-tooltip" :style="{
    bottom: bottom, left: left, visibility: visibility ? 'visible' : 'hidden'
  }">
	<template v-if="nameDisplay == '幼儿园'">
		<div class="tooltip-title tooltip-row">
			<div class="tooltip-row flex-grow">
				<img class="tooltip-icon" src="@/assets/map/build.png" alt="" srcset="">
				楼栋信息
			</div>
			<div style="font-size: 1.85vh;">
				幼儿园
			</div>
		</div>
		<div class="tooltip-row">
			<div class="row-key">教师</div>
			<div class="row-val">24 名</div>
		</div>
		<div class="tooltip-row">
			<div class="row-key">幼儿</div>
			<div class="row-val">527 名</div>
		</div>
		<div class="tooltip-row">
			<div class="row-key">班级</div>
			<div class="row-val">10</div>
		</div>
		<div class="tooltip-row">
			<div class="tooltip-row flex-grow">
				<div class="row-key">小班</div>
				<div class="row-val">3</div>
			</div>
			<div class="tooltip-row flex-grow">
				<div class="row-key">中班</div>
				<div class="row-val">3</div>
			</div>
			<div class="tooltip-row flex-grow">
				<div class="row-key">大班</div>
				<div class="row-val">4</div>
			</div>
		</div>
	</template>
    <template v-else-if="info">
      <div class="tooltip-title tooltip-row">
        <div class="tooltip-row flex-grow">
          <img class="tooltip-icon" src="@/assets/map/build.png" alt="" srcset="">
          楼栋信息
        </div>
        <div style="font-size: 1.85vh;">
          {{ info.buildingNumber }}
        </div>
      </div>
      <div class="tooltip-row">
        <div class="row-key">楼栋长</div>
        <div class="row-val">{{ info.buildingManager}}</div>
      </div>
      <div class="tooltip-row">
        <div class="tooltip-row flex-grow">
          <div class="row-key">住户</div>
          <div class="row-val">{{ info.householdNumber }}</div>
        </div>
        <div class="tooltip-row flex-grow">
          <div class="row-key">居民</div>
          <div class="row-val">{{ info.residentNumber }}</div>
        </div>
        <div class="tooltip-row flex-grow">
          <div class="row-key">党员数</div>
          <div class="row-val">{{ info.partyMemberNumber }}</div>
        </div>
      </div>
      <div class="tooltip-title tooltip-row">
        <div class="tooltip-row">
          <img class="tooltip-icon" src="@/assets/map/star.png">
          社区关爱
        </div>
      </div>
      <div class="tooltip-row">
        <div class="tooltip-row flex-grow">
          <div class="row-key">预警人员</div>
          <div class="row-val">{{ info.warningPersonnelNumber }}</div>
        </div>
        <div class="tooltip-row flex-grow">
          <div class="row-key">关爱人数</div>
          <div class="row-val">{{ info.caringPersonnelNumber }}</div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="tooltip-title tooltip-row">
        <div class="tooltip-row flex-grow">
          <img class="tooltip-icon" src="@/assets/map/build.png" alt="" srcset="">
          楼栋信息
        </div>
        <div style="font-size: 1.85vh;">
          {{ nameDisplay }}
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { ModolData } from '@/utils/mapUtils'
const modelData = new ModolData()
function getInfo(name) {
	return modelData.ready.then(() => {
		return modelData.getInfo(name);
	})
}
export default {
  name: 'MapMousetip',
  props: ['bottom', 'left', 'name', 'visibility'],
  data() {
    return {
		info: undefined,
		residents: [],
		buildingInfo: [],
		// 临时写死的数据
		buildingLeaders:[
		{
			name: '日勒车牛',
			sex: 0,
			phone: 15807699592,
			birthday: '1974-06-06'
		},{
			name: '麻足伍加',
			sex: 0,
			phone: 13518425004,
			birthday: '1990-10-25'
		},{
			name: '孙子日且',
			sex: 1,
			phone: 13568657218,
			birthday: '1976-05-21'
		},{
			name: '俄则工人',
			sex: 1,
			phone: 18283464311,
			birthday: '1980-05-01'
		},{
			name: '甲拉有则',
			sex: 0,
			phone: 17381648573,
			birthday: '1987-09-16'
		},{
			name: '阿尔拉体',
			sex: 1,
			phone: 19949625290,
			birthday: '1960-12-31'
		},{
			name: '海来木史洗',
			sex: 0,
			phone: 19115717121,
			birthday: '1994-05-13'
		},{
			name: '吉克日格',
			sex: 1,
			phone: 19115706840,
			birthday: '1984-01-09'
		},{
			name: '吉米尔则',
			sex: 1,
			phone: 15282977107,
			birthday: '1965-04-27'
		},{
			name: '吉布车古',
			sex: 0,
			phone: 19136026545,
			birthday: '1990-09-06'
		},{
			name: '甲拉拉洗',
			sex: 0,
			phone: 18111407545,
			birthday: '1986-03-04'
		},{
			name: '柳日子黑',
			sex: 1,
			phone: 17311720319,
			birthday: '1973-11-15'
		},{
			name: '甲拉仁作',
			sex: 0,
			phone: 18282817850,
			birthday: '1995-04-13'
		},{
			name: '切沙日洛',
			sex: 0,
			phone: 13440194189,
			birthday: '1980-05-14'
		},{
			name: '马吉牛洗',
			sex: 0,
			phone: 18728988905,
			birthday: '1972-04-03'
		},{
			name: '海来牛洗',
			sex: 0,
			phone: 19136003509,
			birthday: '1971-11-20'
		},{
			name: '俄拉者',
			sex: 1,
			phone: 13778674269,
			birthday: '1960-08-06'
		},{
			name: '俄则哈日',
			sex: 1,
			phone: 18283419453,
			birthday: '1972-12-18'
		},{
			name: '吉子莫子勒',
			sex: 0,
			phone: 18181297053,
			birthday: '1988-12-11'
		},{
			name: '尔古尼日',
			sex: 1,
			phone: 19960277390,
			birthday: '1984-04-05'
		},{
			name: '勒尔体哈',
			sex: 1,
			phone: 19950413475,
			birthday: '1983-03-05'
		},{
			name: '吉尔木呷',
			sex: 1,
			phone: 13077805836,
			birthday: '1966-11-29'
		},{
			name: '勒格尔洗',
			sex: 0,
			phone: 18882065007,
			birthday: '1993-06-30'
		},{
			name: '阿牛子五',
			sex: 1,
			phone: 19115746312,
			birthday: '1962-06-19'
		},{
			name: '切吉阿哈',
			sex: 0,
			phone: 15808293881,
			birthday: '1982-07-07'
		},{
			name: '勒尔伍果',
			sex: 0,
			phone: 17828575628,
			birthday: '1962-06-02'
		},{
			name: '吉足尾几',
			sex: 1,
			phone: 15828770407,
			birthday: '1978-12-15'
		},{
			name: '阿牛拉洛',
			sex: 0,
			phone: 15760251029,
			birthday: '1970-08-19'
		},{
			name: '马海伟曲',
			sex: 1,
			phone: 18113256395,
			birthday: '1995-03-27'
		},{
			name: '阿地布都',
			sex: 0,
			phone: 15884036344,
			birthday: '1998-12-13'
		},{
			name: '阿比阿甲',
			sex: 0,
			phone: 19181559909,
			birthday: '1998-05-10'
		},{
			name: '日力么次洛',
			sex: 0,
			phone: 13084366502,
			birthday: '1988-04-09'
		},{
			name: '沙马曲哈',
			sex: 1,
			phone: 18481559907,
			birthday: '2010-11-15'
		},{
			name: '瓦其你哈',
			sex: 1,
			phone: 13568651454,
			birthday: '1998-02-01'
		},{
			name: '土比依作',
			sex: 0,
			phone: 18583515530,
			birthday: '2001-07-13'
		},{
			name: '米色么莎琪',
			sex: 0,
			phone: 13881551273,
			birthday: '1992-08-17'
		}]
    }
  },
  computed: {
    nameDisplay() {
      if (this.name == 'kindergarten') return '幼儿园';
      if (this.name == '2-G-2') return '社区服务中心';
      if (this.name == 'group_43') return '技能培训中心';
      if (this.name == '2-G-1') return '社区医养中心';
      if (this.name == 'group_43') return '';
      if (this.name == 'group_43') return '';
      if (this.name == '2-G-2_1'
      || this.name == '2-S-5'
      || this.name == '2-S-6'
      || this.name == '2-S-7'
      || this.name == '2-S-8'
      || this.name == '2-S-1'
      || this.name == '2-S-2'
      || this.name == '2-S-3'
      || this.name == '2-S-4'
      || this.name == '2-G-1_1'
      ) return '商业区';
      return this.name;
    }
  },
  watch: {
    name: function (val, oldVal) {
		if (val !== oldVal){
			return getInfo(val).then(info => {
				// getInfo调用的是楼栋模型列表的信息，由于后端未将楼栋信息与居民信息的数据表关联返回结果，
				// 所以考虑在前端将居民列表的信息载入到info
				let nameTable = ['一栋','二栋','三栋','四栋','五栋','六栋','七栋','八栋','九栋','十栋','十一栋','十二栋','十三栋','十四栋','十五栋','十六栋','十七栋','十八栋','十九栋','二十栋','二十一栋','二十二栋','二十三栋','二十四栋','二十五栋','二十六栋','二十七栋','二十八栋','二十九栋','三十栋','三十一栋','三十二栋','三十三栋','三十四栋','三十五栋','三十六栋'];
				//info里唯一有用的信息就是buildingNumber，可以用于定位到当前楼栋在this.buildingInfo中的下标
				let {buildingNumber} = info;
				let x = nameTable.indexOf(buildingNumber);
				let d = this.buildingInfo[x];
				this.info = d;
			}).catch(() => {
				this.info = undefined;
			})
		}
    }
  },
  mounted(){
	this.$api.loadClassifyList({
		pageSize: 99999999
	}).then(res => {
		let d = res.data.rows;
		let nameTable = ['一栋','二栋','三栋','四栋','五栋','六栋','七栋','八栋','九栋','十栋','十一栋','十二栋','十三栋','十四栋','十五栋','十六栋','十七栋','十八栋','十九栋','二十栋','二十一栋','二十二栋','二十三栋','二十四栋','二十五栋','二十六栋','二十七栋','二十八栋','二十九栋','三十栋','三十一栋','三十二栋','三十三栋','三十四栋','三十五栋','三十六栋'];
		let sl = ['高龄','特扶','返贫','辍学','残疾','优抚','失业','留守','独居','低保','问题','涉毒','艾滋','孤儿','监控','困境','退役','特困'];
		//在前端创建楼栋信息表，包含楼栋简要信息的字段
		let buildingInfo = [];
		for(let i = 0; i < 36;i++){
			buildingInfo.push({
				buildingNumber: nameTable[i],//楼栋号，汉字
				buildingManager: '阿岱表仁',//楼栋长，无数据时，默认显示
				householdNumber: 0,//住户数
				residentNumber: 0,//居民数
				partyMemberNumber: 0,//党员数
				warningPersonnelNumber: 0,//预警人员数
				caringPersonnelNumber: 0, //关爱人员数
				households: [] //用于记录当前楼栋的住户房间号详情
			});
		}
		//遍历居民表，把楼栋长名称、居民数、党员数、关爱和预警都统计出来
		for(let i = 0;i < d.length;i++){
			let p = d[i];
			let x = nameTable.indexOf(p.building);
			if(x >= 0){
				let b = buildingInfo[x];
				b.residentNumber++; 
				let h = b.households;
				let bb = x+1;
				let grp = p.groupId;
				let rm = p.roomNumber;
				let hsn = '' + bb + grp + rm;
				if(h.indexOf(hsn) < 0){
					h.push(hsn);
					b.householdNumber++;
				}
				let labels = [];
				if(p.label && p.label.length){
					labels = JSON.parse(p.label);
				}
				/*if(labels.length && labels.indexOf('楼栋长') != -1){
					//当前遍历到的居民是楼栋长，将其名称放在对应楼栋
					b.buildingManager = p.nameCn;
				}*/
				//临时写死数据
				b.buildingManager = this.buildingLeaders[x].name;
				if(p.isPartyMember){
					//当前遍历到的居民是党员
					b.partyMemberNumber++;
				}
				if(labels.length){
					for(let j = 0;j < labels.length;j++){
						let xx = sl.indexOf(labels[j]);
						if(xx >= 0){
							//该人员是关爱人员
							b.caringPersonnelNumber++;
							break;
						}else{
							//针对高龄人员，也要记录到该楼栋的关爱人员下
							let {birthday} = p;
							let age = this.$u.getAge(birthday);
							if(age >= this.$c.highAgeIs){
								b.caringPersonnelNumber++;
							}
						}
					}
				}
			}
		}
		this.buildingInfo = buildingInfo;
		d = null; //d数组可能较大，统计完毕后清除它释放内存
	}).catch(err => {
		console.log(err);
	})
  }

}
</script>
<style lang="less">
.map-container {
  .map-tooltip {
    position: absolute;
    z-index: 99;
    width: 15.3125vw;
    border: 1px solid;
    background: linear-gradient(90deg, rgba(72, 64, 42, 0.4) 0%, rgba(61, 61, 61, 0) 100%);
    border-image: linear-gradient(270deg, rgba(255, 208, 59, 1), rgba(255, 208, 59, 0.2)) 1 1;
    box-shadow: 0px 0px 20px 0px rgba(255, 208, 59, 0.2);
    pointer-events: none;
    color: white;
    padding: 1.48vh;
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    gap: 0.92vh;
	user-select: none;
  }

  .tooltip-title {
    font-family: 'titleFont';
    font-size: 1.67vh;
    font-weight: 400;
    text-shadow: 0px 0px 9px rgba(255, 208, 59, 0.7);
  }

  .tooltip-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    .row-key {
      color: rgba(255, 255, 255, 0.8);
      font-weight: 400;
      font-size: 1.48vh;
      margin-right: 0.42vw;
    }

    .row-val {
      color: white;
      font-size: 1.48vh;
      font-weight: 500;
    }
  }

  .flex-grow {
    flex-grow: 1;
  }

  .tooltip-icon {
    width: 2.96vh;
    aspect-ratio: 1/1;
  }
}
</style>