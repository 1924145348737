<template>
  <span>
    <div class="map-container" ref="container">
      <MapMousetip :bottom="tipPayload.bottom" :left="tipPayload.left" :name="tipPayload.name"
        :visibility="tipPayload.visibility"></MapMousetip>
    </div>
    <div class="map-mask" @click="hideMask" :style="{ visibility: maskVisibility ? 'visible' : 'hidden' }"></div>
    <building-detail class="buildingDetail" :detailsTable="detailsTable" :buildingName="buildingDetailName" @closeWindow="closeDetail" v-if="showDetail=='building'"></building-detail>
    <party-detail class="buildingDetail" v-if="showDetail=='party'" @closeWindow="closeDetail" :dataInput="partyMemberList"></party-detail>
    <big-video class="buildingDetail" style="margin-top: 21.48vh" v-if="showDetail=='video'" :videoUrl="videoUrl" :videoName="videoName" @closeWindow="closeDetail"></big-video>
  </span>
</template>

<script>
import { ZhaomeiMap, loadFileAsUrl } from '@wuch96/threejs-zhaomei'
import MapMousetip from './views/components/map/MapMousetip.vue'
import BuildingDetail from './views/components/map/buildingDetail.vue'
import PartyDetail from './views/components/map/partyDetail.vue'
import bigVideo from './views/components/map/bigVideo.vue'
import { nameFilter } from '@/utils/mapUtils'
//console.log(zhaomeiConfig);
//const loadPromise = loadFileAsUrl(zhaomeiConfig.gltfUrl) //请求远程资源
const loadPromise = loadFileAsUrl('scene.glb') //请求静态资源（开发）
/*global zhaomeiConfig*/
export default {
  name: 'Map',
  components: { MapMousetip, BuildingDetail, PartyDetail, bigVideo },
  data() {
    return {
      tipPayload: {
        bottom: '0px',
        left: '0px',
        name: '',
        visibility: false,
      },
      videoUrl: '',
      videoName: '',
      buildingDetailName: '',
      showDetail: '',
      maskVisibility: true,
      flytoEntityHandle: null,
      showMaskSTO: null,
      partyMemberList: [[],[],[],[],[],[],[]],
      siv: null,
      detailsTable:{
        residents:[],
        partyMembers:[]
      }
    }
  },
  async mounted() {
    this.handleInit();
    //事件源：login
    /* this.$eventBus.$on('loginSuccess', () => {
      this.handleInit();
    }) */
  },
  watch: {
    showDetail(s){
      if(s){
        this.$eventBus.$emit('closeNotice');
      }else{
        this.$eventBus.$emit('openNotice');
      }
    }
  },
  methods: {
    handleInit(){
      this.initMap();
      this.initResidentData();
      //每半小时更新一次社区居民信息
      clearInterval(this.siv);
      this.siv = setInterval(() => {
        this.initResidentData();
      }, 1800000)
    },
    //初始化整个地图
    async initMap(){
      const instance = new ZhaomeiMap(this.$refs.container)
      instance.controls
      //const gltfUrl = 'https://103.91.210.232:13890/output/community.gltf'; //初始nginx节点
      const gltfUrl = await loadPromise; //成都nginx节点
      //const gltfUrl = './src/assets/gltf/community.gltf'; //本地gltf资源
      instance.loadGltf(gltfUrl)
      this.flytoEntityHandle = instance.controls.reset.bind(instance)
      instance.observer.on('mousemove', payload => {
        this.showMask()
        if (payload == null || !nameFilter(payload.name)) {
          this.tipPayload.visibility = false
        } else {
          this.tipPayload.visibility = true
          this.tipPayload.bottom = payload.bottom
          this.tipPayload.left = payload.left
          this.tipPayload.name = payload.name
        }
      })
      let lastClickName
      instance.observer.on('click', payload => {
        if (payload == null || !nameFilter(payload.name)){
          this.showDetail = ''
          return false
        }else{
      //点击非居民楼不显示楼栋详情
      if(payload.name != 'kindergarten'
        && payload.name != '2-G-2'
        && payload.name != '2-G-2_1'
        && payload.name != '2-G-1'
        && payload.name != '2-G-1_1'
        && payload.name != '2-S-5'
        && payload.name != '2-S-6'
        && payload.name != '2-S-7'
        && payload.name != '2-S-8'
        && payload.name != '2-S-1'
        && payload.name != '2-S-2'
        && payload.name != '2-S-3'
        && payload.name != '2-S-4'
        ){
        this.showDetail = 'building'
      }else{
        this.showDetail = ''
      }
          this.buildingDetailName = payload.name
        }
        const name = payload.name
        instance.unHighLight()
        if (lastClickName !== name) {
          const t = instance.scene.getObjectByName(name)
          if (t) {
            instance.highLight(t)
            lastClickName = t.name
          }
        } else {
          lastClickName = null
        }
      })
      //事件源： page1.vue
      this.$eventBus.$on('showPartyDetail',ev => {
        if(this.showDetail != 'party'){
          this.partyMemberList = ev
          this.showDetail = 'party'
        }else{
          this.showDetail= '';
        }
      })
      this.$eventBus.$on('showBigVideo', ev => {
        this.showDetail = 'video';
        this.videoUrl = ev.url;
        this.videoName = ev.videoName;
      })
      this.$eventBus.$on('closeDetailWindow',() => {
        this.showDetail = '';
      })
    },
    //初始化居民数据
    initResidentData(){
      let p1 = new Promise((resolve,reject) => {
        this.$api.loadClassifyList({
          pageSize: 9999999
        }).then(res => {
          resolve(res.data.rows);
        }).catch(err => {
          reject(err);
        })
      })
      let p2 = new Promise((resolve, reject) => {
        this.$api.getPartyMembers({
          pageSize: 9999999
        }).then(res => {
          resolve(res.data.rows);
        }).catch(err => {
          reject(err);
        })
      })
      Promise.all([p1, p2]).then(res => {
        let d0 = res[0]; //居民列表
        let d1 = res[1]; //党员列表
        let nameTable = ['一栋','二栋','三栋','四栋','五栋','六栋','七栋','八栋','九栋','十栋','十一栋','十二栋','十三栋','十四栋','十五栋','十六栋','十七栋','十八栋','十九栋','二十栋','二十一栋','二十二栋','二十三栋','二十四栋','二十五栋','二十六栋','二十七栋','二十八栋','二十九栋','三十栋','三十一栋','三十二栋','三十三栋','三十四栋','三十五栋','三十六栋'];
        let t = {
          residents: [],
          partyMembers: [],
          buildingLeaders: []
        }
        for(let i = 0;i < 36;i++){
          t.residents.push([]);
          t.partyMembers.push([]);
        }
        for(let j = 0;j < d0.length;j++){
          let p = d0[j];
          let b = p.building;
          let x = nameTable.indexOf(b);
          if(x >= 0){
            t.residents[x].push(p);
            let label = JSON.parse(p.label);
            if(label && label.indexOf('楼栋长') >= 0){
              t.buildingLeaders[x] = p;
            }
          }
        }
        for(let k = 0;k < d1.length;k++){
          let p = d1[k];
          let b = p.building;
          let x = nameTable.indexOf(b);
          if(x >= 0){
            t.partyMembers[x].push(p);
          }
        }
        this.detailsTable = t;
      }).catch(err => {
        console.log(err);
      })
      /* this.$api.loadClassifyList({
        pageSize: 9999999
      }).then(res => {
        let d = res.data.rows;
        console.log(d);
        
      }).catch(err => {
        console.log(err);
      }) */
    },
    hideMask() {
      loadPromise.then(() => {
        this.maskVisibility = false
        this.showMask()
      })
    },
    showMask() {
      if (this.showMaskSTO !== null) clearTimeout(this.showMaskSTO)
      this.showMaskSTO = setTimeout(() => {
        this.maskVisibility = true
        this.flytoEntityHandle?.()
      }, 1000 * zhaomeiConfig.reshowMask)
    },
    closeDetail(){
      this.showDetail = ''
    }
  },
  beforeDestroy() {
    clearInterval(this.siv);
  }
}
</script>
<style lang="less">
.map-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  background-color: grey;
}

.buildingDetail {
    position: fixed;
    right: 22.92vw;
    top: 8.98vh;
}

.map-mask {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0px;
  top: 0px;
  background-image: url('~@/assets/map/render-bg.png');
  background-position: center;
  background-size: auto 100%;
  z-index: 10;
}
</style>
