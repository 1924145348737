<template>
  <div class="container" :style="{bottom: bottom, left: left}">
    <div style="display: flex; justify-content: space-between; width: 15.99vw">
      <div class="bigTitle">{{info?info.buildingNumber:''}}</div>
      <div class="closeButton" @click="closeWindow">
        <img src="@/assets/common/close.png" class="closeBtnImg" alt="">
      </div>
    </div>
    <div class="tooltip-title tooltip-row">
      <div class="tooltip-row flex-grow">
        <img class="tooltip-icon" src="@/assets/map/build.png" alt="" srcset="">
        <span style="vertical-align: bottom;">楼栋长信息</span>
      </div>
    </div>
    <person-profile-4 class="mainProfile" :dataInput="inhabitantProfile.buildingManager"></person-profile-4>
    <div class="tooltip-title tooltip-row" style="margin-top: 2.32vh;">
      <div class="tooltip-row flex-grow">
        <img class="tooltip-icon" src="@/assets/map/build.png" alt="" srcset="">
        <span style="vertical-align: bottom;">居民信息</span>
      </div>
    </div>
    <div class="buttonRow">
      <long-button class="longButton" itemName="居民" :value="inhabitantProfile.data.length?inhabitantProfile.data[0].total:0" :selected="selectedIndex==1" @click="()=>{selectType(1)}"></long-button>
      <long-button class="longButton" itemName="党员" :value="inhabitantProfile.data.length?inhabitantProfile.data[1].total:0" :selected="selectedIndex==2" @click="()=>{selectType(2)}"></long-button>
    </div>
    <div class="buttonRow">
      <long-button class="longButton" itemName="预警" :value="inhabitantProfile.data.length?inhabitantProfile.data[2].total:0" :selected="selectedIndex==3" @click="()=>{selectType(3)}"></long-button>
      <long-button class="longButton" itemName="关爱" :value="inhabitantProfile.data.length?inhabitantProfile.data[3].total:0" :selected="selectedIndex==4" @click="()=>{selectType(4)}"></long-button>
    </div>
    <div class="scrollList">
      <!-- 手动滚动 -->
      <div class="scrollInnerbox" ref="scroller" @mousedown.prevent="scrollMousedown" @mousemove.prevent="scrollMousemove" @touchstart.prevent="handleTouchstart" @touchmove.prevent="handleTouchmove" @touchend="handleTouchend"  :style="{top: curY+'px'}">
        <person-profile-5 
        class="personProfile"
        v-for="(item, index) in inhabitantProfile.data.length?inhabitantProfile.data[selectedIndex-1].rows:[]" :key="index"
        :dataInput="item"></person-profile-5>
      </div>
    </div>
  </div>
</template>
<script>
import { ModolData } from '@/utils/mapUtils'
const personProfile4 = () => import('@/views/components/personProfile4.vue');
const personProfile5 = () => import('@/views/components/personProfile5.vue');
const longButton = () => import('@/views/components/longButton.vue');
//const scrollview = () => import('@/views/components/scrollview.vue');
const modelData = new ModolData()
function getInfo(name) {
  return modelData.ready.then(() => {
    return modelData.getInfo(name)
  })
}
export default {
  name: 'buildingDetail',
  props: ['bottom', 'left', 'buildingName','detailsTable'],
  data() {
    return {
      info: undefined,
      inhabitantProfile:{
        buildingManager:{
          nameCn: '加载中',
          birthday: '2023-12-10',
          education: '加载中',
          sex: '男',
          groupId: '',
          building: '',
          roomNumber: '加载中',
          phone: '加载中',
          face: 'https://img2.baidu.com/it/u=3796817783,1451973399&fm=253&fmt=auto&app=138&f=JPEG?w=386&h=500'
        },
        data:[]
      },
      selectedIndex: 1,
      canDrag: false,
      scrollerHeight: 0,
      outerHeight: 0,
      curY: 0,
      startY: 0,
      startMouseY: 0,
		// 临时写死的数据
		buildingLeaders:[
		{
			name: '日勒车牛',
			sex: 0,
			phone: 15807699592,
			birthday: '1974-06-06'
		},{
			name: '麻足伍加',
			sex: 0,
			phone: 13518425004,
			birthday: '1990-10-25'
		},{
			name: '孙子日且',
			sex: 1,
			phone: 13568657218,
			birthday: '1976-05-21'
		},{
			name: '俄则工人',
			sex: 1,
			phone: 18283464311,
			birthday: '1980-05-01'
		},{
			name: '甲拉有则',
			sex: 0,
			phone: 17381648573,
			birthday: '1987-09-16'
		},{
			name: '阿尔拉体',
			sex: 1,
			phone: 19949625290,
			birthday: '1960-12-31'
		},{
			name: '海来木史洗',
			sex: 0,
			phone: 19115717121,
			birthday: '1994-05-13'
		},{
			name: '吉克日格',
			sex: 1,
			phone: 19115706840,
			birthday: '1984-01-09'
		},{
			name: '吉米尔则',
			sex: 1,
			phone: 15282977107,
			birthday: '1965-04-27'
		},{
			name: '吉布车古',
			sex: 0,
			phone: 19136026545,
			birthday: '1990-09-06'
		},{
			name: '甲拉拉洗',
			sex: 0,
			phone: 18111407545,
			birthday: '1986-03-04'
		},{
			name: '柳日子黑',
			sex: 1,
			phone: 17311720319,
			birthday: '1973-11-15'
		},{
			name: '甲拉仁作',
			sex: 0,
			phone: 18282817850,
			birthday: '1995-04-13'
		},{
			name: '切沙日洛',
			sex: 0,
			phone: 13440194189,
			birthday: '1980-05-14'
		},{
			name: '马吉牛洗',
			sex: 0,
			phone: 18728988905,
			birthday: '1972-04-03'
		},{
			name: '海来牛洗',
			sex: 0,
			phone: 19136003509,
			birthday: '1971-11-20'
		},{
			name: '俄拉者',
			sex: 1,
			phone: 13778674269,
			birthday: '1960-08-06'
		},{
			name: '俄则哈日',
			sex: 1,
			phone: 18283419453,
			birthday: '1972-12-18'
		},{
			name: '吉子莫子勒',
			sex: 0,
			phone: 18181297053,
			birthday: '1988-12-11'
		},{
			name: '尔古尼日',
			sex: 1,
			phone: 19960277390,
			birthday: '1984-04-05'
		},{
			name: '勒尔体哈',
			sex: 1,
			phone: 19950413475,
			birthday: '1983-03-05'
		},{
			name: '吉尔木呷',
			sex: 1,
			phone: 13077805836,
			birthday: '1966-11-29'
		},{
			name: '勒格尔洗',
			sex: 0,
			phone: 18882065007,
			birthday: '1993-06-30'
		},{
			name: '阿牛子五',
			sex: 1,
			phone: 19115746312,
			birthday: '1962-06-19'
		},{
			name: '切吉阿哈',
			sex: 0,
			phone: 15808293881,
			birthday: '1982-07-07'
		},{
			name: '勒尔伍果',
			sex: 0,
			phone: 17828575628,
			birthday: '1962-06-02'
		},{
			name: '吉足尾几',
			sex: 1,
			phone: 15828770407,
			birthday: '1978-12-15'
		},{
			name: '阿牛拉洛',
			sex: 0,
			phone: 15760251029,
			birthday: '1970-08-19'
		},{
			name: '马海伟曲',
			sex: 1,
			phone: 18113256395,
			birthday: '1995-03-27'
		},{
			name: '阿地布都',
			sex: 0,
			phone: 15884036344,
			birthday: '1998-12-13'
		},{
			name: '阿比阿甲',
			sex: 0,
			phone: 19181559909,
			birthday: '1998-05-10'
		},{
			name: '日力么次洛',
			sex: 0,
			phone: 13084366502,
			birthday: '1988-04-09'
		},{
			name: '沙马曲哈',
			sex: 1,
			phone: 18481559907,
			birthday: '2010-11-15'
		},{
			name: '瓦其你哈',
			sex: 1,
			phone: 13568651454,
			birthday: '1998-02-01'
		},{
			name: '土比依作',
			sex: 0,
			phone: 18583515530,
			birthday: '2001-07-13'
		},{
			name: '米色么莎琪',
			sex: 0,
			phone: 13881551273,
			birthday: '1992-08-17'
		}]
    }
  },
  components:{
    personProfile4,
    personProfile5,
    longButton,
    //scrollview
  },
  mounted(){
    document.onmouseup = () => {
		this.canDrag = false;
    }
    document.ontouchend = () => {
      this.canDrag = false;
    }
    getInfo(this.buildingName).then(info => {
      this.info = info;
      this.initData();
    }).catch(() => {
      this.info = undefined;
    })
    return false;
  },
  methods: {
    initData(){
		this.selectedIndex = 0;
		this.selectedIndex = 1;
		let d = this.detailsTable;
		if(d){
			let nameTable = ['一栋','二栋','三栋','四栋','五栋','六栋','七栋','八栋','九栋','十栋','十一栋','十二栋','十三栋','十四栋','十五栋','十六栋','十七栋','十八栋','十九栋','二十栋','二十一栋','二十二栋','二十三栋','二十四栋','二十五栋','二十六栋','二十七栋','二十八栋','二十九栋','三十栋','三十一栋','三十二栋','三十三栋','三十四栋','三十五栋','三十六栋'];
			let sl = ['高龄','特扶','返贫','辍学','残疾','优抚','失业','留守','独居','低保','问题','涉毒','艾滋','孤儿','监控','困境','退役','特困'];
			let n = this.info.buildingNumber;
			let x = nameTable.indexOf(n);
			if(x >= 0){
				let dr = d.residents[x];
				let loveList = [];
				for(let i = 0;i < dr.length;i++){
					let p = dr[i];
					let labels = [];
					if(p.label && p.label.length){
						labels = JSON.parse(p.label);
					}
					if(labels.length){
						for(let j = 0;j < labels.length;j++){
							let xx = sl.indexOf(labels[j]);
							if(xx >= 0){
								loveList.push(p);
								break;
							}else{
								let {birthday} = p;
								let age = this.$u.getAge(birthday);
								if(age >= this.$c.highAgeIs){
									loveList.push(p);
									break;
								}
							}
						}
					}
					
				}
				let t = {
				data: [
					{
						type: '居民',
						total: dr.length,
						rows: dr
					},{
						type: '党员',
						total: d.partyMembers[x].length,
						rows: d.partyMembers[x]
					},{
						type: '预警',
						total: 0,
						rows: []
					},{
						type: '关爱',
						total: loveList.length,
						rows: loveList
					}
				]
			}
			/*if(d.buildingLeaders[x]){
				t.buildingManager = d.buildingLeaders[x];
			}else{
				//暂时放入假数据
				t.buildingManager = {
					nameCn: '阿岱表仁',
					birthday: '2000-12-11',
					education: '大专',
					sex: '男',
					groupId: '3组',
					building: '1栋',
					roomNumber: '302',
					phone: '13299999999',
					face: 'https://img2.baidu.com/it/u=3796817783,1451973399&fm=253&fmt=auto&app=138&f=JPEG?w=386&h=500'
				}
			}*/
			let bm = this.buildingLeaders;
			t.buildingManager = {
				nameCn: bm[x].name,
				birthday: bm[x].birthday,
				sex: bm[x].sex?'男':'女',
				phone: bm[x].phone + '',
				face: './'+(x+1)+'.png'
			}
			this.inhabitantProfile = t;
        }
      } 
    },
    selectType(n){
      this.selectedIndex = n;
    },
    closeWindow(){
      this.$emit('closeWindow');
    },
    scrollMousedown(ev){
      this.startMouseY = ev.clientY;
      this.scrollerHeight = this.$refs.scroller.offsetHeight;
      this.outerHeight = this.$refs.scroller.parentElement.offsetHeight;
      this.startY = this.curY;
      this.canDrag = true;
    },
    scrollMousemove(ev){
      if(this.canDrag){
        let mY = ev.clientY;
        let sY = this.startY;
        let smY = this.startMouseY;
        let dis = mY - smY;
        let cY = sY + dis;
        let sH = this.scrollerHeight;
        let h = this.outerHeight;
        if(cY < h-sH){
          cY = h - sH;
        }
        if(cY >0){
          cY = 0;
        }
        this.curY = cY;
      }
    },
	//兼容触屏
	handleTouchstart(ev){
		let touch = ev.touches[0];
		this.startMouseY = touch.clientY;
		this.scrollerHeight = this.$refs.scroller.offsetHeight;
		this.outerHeight = this.$refs.scroller.parentElement.offsetHeight;
		this.startY = this.curY;
		this.canDrag = true;
	},
	handleTouchmove(ev){
		if(this.canDrag){
			let touch = ev.touches[0];
			let mY = touch.clientY;
			let sY = this.startY;
			let smY = this.startMouseY;
			let dis = mY - smY;
			let cY = sY + dis;
			let sH = this.scrollerHeight;
			let h = this.outerHeight;
			if(cY < h-sH){
				cY = h - sH;
			}
			if(cY >0){
				cY = 0;
			}
			this.curY = cY;
		}
	},
	handleTouchend(){
		this.canDrag = false;
	}
  },
  watch: {
    buildingName: function (val, oldVal) {
		if (val === oldVal) return
		getInfo(val).then(info => {
		this.info = info
    this.initData()
		}).catch(() => {
		this.info = undefined
		})
		this.curY = 0;
		this.startY = 0;
    },
	selectedIndex(){
		this.curY = 0;
		this.startY = 0;
	}
  },

}
</script>
<style lang="less" scoped>
.container {

  z-index: 9999;
  width: 19.115vw;
  height: 82.5vh;
  border: 1px solid;
  background: linear-gradient(90deg, rgba(72, 64, 42, 0.4) 0%, rgba(61, 61, 61, 0) 100%);
  border-image: linear-gradient(270deg, rgba(255, 208, 59, 1), rgba(255, 208, 59, 0.2)) 1 1;
  box-shadow: 0px 0px 20px 0px rgba(255, 208, 59, 0.2);
  padding-left: 1.5625vw;
  padding-top: 2.78vh;
  text-wrap: nowrap;
  color: #fff;
  /*gap: 0.92vh;*/
  overflow: hidden;

  .bigTitle {
    font-family: 'titleFont';
    font-size: 2.22vh;
    font-weight: 400;
    background: linear-gradient(180deg, #FFFFFF 42%, #FFD03B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .closeButton {
    height: 2.22vh;
    aspect-ratio: 1/1;
    cursor: pointer;

    .closeBtnImg {
      width: 100%;
      height: 100%;
    }
  }
  .closeButton:hover {
	background: rgba(255, 208, 59, 0.2);
  }
  .tooltip-title {
    font-family: 'titleFont';
    font-size: 1.67vh;
    font-weight: 400;
    height: 2.96vh;
    text-shadow: 0px 0px 9px rgba(255,208,59,0.7);
  }

  .tooltip-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 2.96vh;
    vertical-align: bottom;

    .row-key {
      color: rgba(255, 255, 255, 0.8);
      font-weight: 400;
      font-size: 1.48vh;
      margin-right: 0.42vw;
    }

    .row-val {
      color: white;
      font-size: 1.48vh;
      font-weight: 500;
    }
  }

  .flex-grow {
    flex-grow: 1;
  }

  .tooltip-icon {
    width: 2.96vh;
    aspect-ratio: 1/1;
  }
  .mainProfile {
    margin-top: 1.48vh;
  }
  .buttonRow {
    height: 2.78vh;
    display: flex;
    justify-content: space-between;
    margin-top: 0.74vh;
    width: 15.99vw;

    .longButton {
      width: 48.86%;
    }
  }
  .scrollList{
    margin-top: 1.48vh;
    height: 48.52vh;
    position: relative;
    overflow: hidden;

    .scrollInnerbox {
      position: absolute;
    }
  }
}
</style>