import ajax from '@/ajax/ajax.js'
export function nameFilter(name) {
  return !name.startsWith('group') && !name.startsWith('community')
}
function sleep(time) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve()
    }, time)
  })
}
export class ModolData {
  map = new Map()
  ready
  constructor() {
    this.ready = new Promise((res) => {
      const fn = () => this.getAll().then(res).catch(()=>{
        sleep(3000).then(fn)
      })
      fn()
    })
  }
  getInfo(name) {
    return this.map.get(name)
  }
  async getAll() {
    const res = await getWebglBuildList()
    res.forEach((el) => {
      this.map.set(el.modelName, el)
    })
  }
}
function getWebglBuildList() {
  return ajax
    .send({
      url: 'dev-api/admin/webgl-building-model/list?pageSize=9999',
      method: 'get',
      auth: true,
      mock: false,
    })
    .then((res) => {
      const rows = res.data?.rows ?? []
      return rows
    })
}
