import axios from 'axios';
import router from '../router';

//ajax.js
/* 
    请不要在这里写api相关的接口，打开./requests.js查看
*/
import { $db } from '../utils';

//const loginPageUrl = ''; //登录用Url，备用
//const baseUrl = 'http://154.9.28.183:8090/'; //后端接口Url，http
//const baseUrl = 'https://154.9.28.183:8089/'; //后端接口Url，https
const baseUrl = 'https://zmsq.domidodo.cn/'; //后端接口Url,带域名
// const baseUrl = 'https://cs.domidodo.com.cn/'; //后端接口Url,带域名

const request = axios.create({
    baseURL:baseUrl,
    timeout:30000
})

const mockRequest = axios.create({
    baseURL: '',
    timeout: 3000
})

const ajax = {
    sendRequest(cfg) {
        let {data, url, method, auth, mock, showLoading, mockUrl} = cfg;
        let params = data || {};
        //该变量用于解决多个时间间隔过短的请求同时弹出多个消息框无法被即使破坏的bug
        if(showLoading !== false){
            showLoading = true;
        }
        var p = new Promise((resolve,reject) => {
            var token = $db.get('token');
			var config;
			if(method === 'post'){
				config = {
					url,
					method,
					data:Object.assign(params,token)
				}; 
			}else if(method === 'get'){
				config = {
					url,
					method,
					params,
				};
			}
			if(auth){
				config.headers = {Authorization: token};
			}
			if(!mock){
				//发起真实请求
				request(config).then(res => {
                    let status = res.data.code;
                    if(status == 401 || status == '401'){
                        // 出现这种情况表示该请求表面上返回了，但实际上token已过期而无法拿到数据。
                        // 此时需要退回到登录页面，重新登录
                        router.push('login');
                    }
					resolve(res);
				}).catch( err => {
					reject(err);
				})
			}else{
                if(mockUrl){
                    config.url = mockUrl;
                }
                mockRequest(config).then(res => {
                    resolve(res);
                }).catch(err => {
                    reject(err);
                })
            }
            
        })
        return p;
    },
    login(data){
        return request.post('dev-api/login',data);
    },
    //请求配置接口，在requests.js中通过该方法与ajax对象交互
    //cfg：配置对象，其中包括数据，url，方法，是否refresh和是否携带请求头token。
    send(cfg){
        return this.sendRequest(cfg);
    }
}

export default ajax;
